.main_divv {
  color: #080a13;
  max-height: 100vh;
}

*::-webkit-scrollbar {
  display: none;
}

.homeOuter {
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.amin {
  height: 90vh;
  display: flex;
  justify-content: center;
}
.homelogo {
  display: flex;
  align-items: center;
}

.homeFullForm {
  margin-left: 10vw;
}

.homeFullForm h1 {
  font-size: 60px;
}
.heading-name {
  font-family: Outfit;
  text-align: center;
  padding-top: 3%;
  text-decoration: underline;
}

a {
  text-decoration: none;
}

/* Hero Section */
#hero {
  text-align: center;
  padding: 100px 20px;
  /* background: radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%); */
  margin-top: 10vh;
}
@media (max-width: 500px) {
  .main_divv {
    display: none;
  }
}

@media (max-width: 1025px) {
  .whole_hhh {
    overflow-x: hidden;
  }
}

#hero h2 {
  font-size: 42px;
  margin-bottom: 20px;
  /* color: #333;
     */
  color: #080a13;
}

#hero p {
  font-size: 18px;
  margin-bottom: 40px;
  /* color: #666; */
  color: #2d2e32;
}

.cta-btn {
  display: inline-block;
  padding: 12px 24px;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.cta-btn:hover {
  background-color: #555;
}

/* add */
.scroller {
  height: 1.2em;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  width: 10em;
}
.scroller > span {
  position: absolute;
  top: 0;
  animation: slide 5s infinite;
  font-weight: bold;
}
@keyframes slide {
  0% {
    top: 0;
  }
  25% {
    top: -1.2em;
  }
  50% {
    top: -2.4em;
  }
  75% {
    top: -3.6em;
  }
}

/* add */
.outer_text_home {
  background: #fff;
  text-align: center;
  min-height: 80vh;

  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.outer_text_home h1 {
  font-size: 3rem;
}

.linear-wipe {
  text-align: center;
  /* background: linear-gradient(to right, #000 20%, #FF0 40%, #FE0 60%, #FFF 80%); */
  background: conic-gradient(red, yellow, lime, aqua, blue, magenta, red);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.loop_head {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
}

.loop_para {
  max-width: 50ch;
  color: #455066;
  letter-spacing: -0.2px;
  margin-top: 12px;
  line-height: 150%;
  margin-bottom: 35px;
}

.whole_loop {
  width: 90%;
  margin: auto;
}

/* .loop_tabs{
    display: flex;
    width:90%;
  } */

/*  */
.loop_tabs {
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  justify-content: center;
}

/* Media query for screens below 430px */
@media (max-width: 430px) {
  .loop_btn {
    margin: 5px; /* Adjust spacing between buttons */
  }
}

/*  */

.loop_btn {
  /* grid-column-gap: 12px; */
  color: #67738c;
  text-align: center;
  letter-spacing: -0.05px;
  background-color: #fff;
  border: 1px solid #c0c8d8;
  border-radius: 10px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  margin-left: 20px;
  /* margin-bottom: 5px; */
  margin-bottom: 30px;
}
.current {
  outline: 2px solid #1f69ff;
}

.hire {
  display: flex;
}
/* .outer_text_home{
    position: absolute;
  left: 27vw; */
/* } */

.centerone {
  font-size: 36px;
  width: 70vw;
  margin: auto;
}

.outer_text_home button {
  margin-top: 30px;
}

@media (max-width: 720px) {
  .loop_btn {
    color: #67738c;
    text-align: center;
    letter-spacing: -0.05px;
    background-color: #fff;
    border: 1px solid #c0c8d8;
    border-radius: 10px;
    padding: 6px 10px;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    margin-left: 3px;
  }
}

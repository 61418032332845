@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');


.whole_blog{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    /* background: #f5f5f5; */
}

.btn_blog{
    display: flex;
    justify-content: center;
}
.btn_blog button{
    padding:8px;
    background: rgba(90, 86, 86, 0.667);
    border-radius: 20px;
    backdrop-filter: blur(16px) saturate(120%);
    color:#fff
}

.wrapper_blog {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 24px;
    flex-wrap: wrap;
}

.card{
    position: relative;
    width: 325px;
    height: 450px;
    background: #000;
    border-radius: 18px;
    overflow: hidden;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
}

.poster {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.poster::before {
    content: '';
    position: absolute;
    bottom: -45%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: .3s;
}

.card:hover .poster::before {
    bottom: 0;
}

.poster img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;
}

.card:hover .poster img {
    transform: scale(1.1);
}

.details {
    position: absolute;
    bottom: -150%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1.5em 1.5em 2em;
    background: #000a;
    backdrop-filter: blur(16px) saturate(120%);
    transition: .3s;
    color: #fff;
    z-index: 2;
}

.card:hover .details {
    bottom: 0;
}

.details h1,
.details h2 {
    font-weight: 700;
}

.details h1 {
    font-size: 1em;
margin-bottom: 5px;
color: white;
}

.details h2 {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 10px;
    opacity: .6;
    color:#fff
}

.details .rating {
    position: relative;
    margin-bottom: 15px;
    display: flex;
    gap: .25em;
}

.details .rating i {
    color: #e3c414;
}

.details .rating span {
    margin-left: 0.25em;
}

.details .tag_blogs {
    display: flex;
    gap: .375em;
    margin-bottom: .875em;
    font-size: .65em;
}

.details .tag_blogs span {
    padding: .35rem .65rem;
    color: #fff;
    border: 1.5px solid rgba(255 255 255 / 0.4);
    border-radius: 4px;
    border-radius: 50px;
}

.details .desc {
    color: #fff;
    opacity: .8;
    line-height: 1.2;
    margin-bottom: 1em;
    font-size: 16px;
}

.details .cast h3 {
    margin-bottom: .5em;
   color: rgba(255,255,255,0.6);
   font-size: 16px;
}

.header_blog{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#000
}
/* 
.details .cast ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    width: 100%;
} */

/* .details .cast ul li {
    list-style: none;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    overflow: hidden;
    border: 1.5px solid #fff;
}



.details .cast ul li img {
    width: 100%;
    height: 100%;
} */

/* .details.cast ul li a {
    color: #ccd3d9;
} */

.other_btn{
    padding: .35rem .65rem;
    color: black;
    border: 1.5px solid rgba(255 255 255 / 0.4);
    border-radius: 4px;
    border-radius: 50px;
    background: auto;
    background: rgba(173, 216, 230,0.5);
    -webkit-backdrop-filter: blur(16px) saturate(120%);
    backdrop-filter: blur(16px) saturate(120%);
    margin: 6vh 0vh 5vh 0vh;
  }

  .category-buttons-filter button:hover{
    padding: .35rem .65rem;
    color: black;
    border: 1.5px solid rgba(5, 4, 4, 0.4);
    border-radius: 4px;
    border-radius: 50px;
    background: auto;
    background:rgba(255,155,255,0.5);
    -webkit-backdrop-filter: blur(16px) saturate(120%);
    backdrop-filter: blur(16px) saturate(120%);
  }


  .category-buttons-filter{
    display: flex;
justify-content: center;
align-content: center;
gap: 2rem
  }

  .active_btn_filter{
    padding: .35rem .65rem;
    color: black;
    border: 1.5px solid rgba(5, 4, 4, 0.4);
    border-radius: 4px;
    border-radius: 50px;
    background: auto;
    background:rgba(255,155,255,0.5);
    -webkit-backdrop-filter: blur(16px) saturate(120%);
    backdrop-filter: blur(16px) saturate(120%);
    margin: 6vh 0vh 3vh 0vh;
  }


  @media(max-width:420px){
    .category-buttons-filter{
        display: flex;
        flex-direction: column;
        gap:20px;
        align-items: center;
    }
  }



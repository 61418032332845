.form-containerss {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.form-box-sample {
  display: flex;
  flex-direction: column;
  /* background-color: #f0f0f0; */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* width: 400px; */
  width: 35vw;
  border-radius: 20px;
}

.freesamplee label {
  margin: 0px;
  /* font-size: 20px; */
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #5E656E;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-top: 10px;
  font-weight: bold;
}

input,
textarea,
select {
  padding: 8px;
  margin-bottom: 10px;
  border: none;
  border-radius: 3px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  /* background: #c2bdbd; */
}

.hahha {
  padding:10px 30px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.hahha:hover {
  background-color: #0069d9;
}
.btn_bookx {
  margin: auto;
}
option {
  padding: 8px;
}

.left__img {
  height: 40vh;
}

.rrr {
  display: flex;
  justify-items: center;
  align-items: center;
}
.haa_bhai {
  margin: auto;
}

@media (max-width: 660px) {
  .form-box-sample {
    width: 70vw;
  }
}

@media (max-width: 380px) {
  .title-lockup {
    font-size: 14px;
  }
}

.form-box-sample input[type="text"],
input[type="password"],
input[type="tel"],
input[type="number"] {
  font-size: 0.8rem;
  color: var(--text-color);
  width: 90%;
  padding: 6px 20px;
  margin: 8px 8px 8px 0;
  display: inline-block;
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
}

.form-box-sample textarea,
select {
  font-size: 0.8rem;
  background: #f4f4f4;
}

.title-lockup {
  margin-top: 20px;
}

.sample_email {
  font-size: 0.7rem !important;
}
.v2-subtitle-sample {
  margin-top: 10px;
  margin-bottom: 60px;
}

.whole_optional_sample ul li {
  list-style: circle;
  margin-bottom: 10px;
}

.whole_down_workss {
  /* background: radial-gradient(
    100.18% 488.14% at 50% 50%,
    rgba(77, 235, 212, 0) 23.23%,
    rgba(77, 235, 212, 0.365) 85.73%
  ); */
  padding: 50px;
  margin: auto;
  width: 100vw;
}
.work_sample {
  /* background-color: radial-gradient(
    100.18% 488.14% at 50% 50%,
    rgba(77, 235, 212, 0) 23.23%,
    rgba(77, 235, 212, 0.365) 85.73%
  ); */
  /* background: radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%); */
}
.work_sample ul li {
  list-style: circle;
}

.note_sample ul li {
  list-style: circle;
  margin-bottom: 10px;
}


.form-containerss h2{
  margin-bottom: 30px;
}


input[type='number']{
  /* background-color: #f4f4f4; */
}
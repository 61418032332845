@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.container {
  width: 100%;
  height: 100%;
  padding: 0px 8%;
}

.container h1 {
  text-align: center;
  padding-top: 10%;
  margin-bottom: 60px;
  font-weight: 600;
  position: relative;
}

.container h1::after {
  content: '';
  background: #303ef7;
  width: 200px;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.row_bene {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 30px;
}

.service {
  text-align: center;
  padding: 25px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  /* background: transparent; */
  /* transition: transform 0.5s,back 0.5s; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.service i {
  font-size: 40px;
  margin-bottom: 10px;
  color: #303ef7;
}

.service h2 {
  font-weight: 600;
  margin-bottom: 8px;
}

.service:hover{
  background: #303ef7;
  transform: scale(1.05);
}

.service:hover h2{
    color: #fff !important;
}
.service:hover p{
    color: #fff !important;
}

.service:hover i {
  color: #fff;
}


.bene_desc{
  margin-bottom:35px;
}

.bene_end{
  margin-top: 30px;
}
/* 
  
  .header_js {
    background-color: #007bff;
    padding: 20px;
    color: #fff;
    text-align: center;
  }
  
  .container_js {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .cta-button_js {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s;
  }
  
  .cta-button_js:hover {
    background-color: #0056b3;
  }
  
  .image_js {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .image_js img {
    max-width: 100%;
    height: auto;
  }
   */

   /* body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    color: #333;
    margin: 0;
    padding: 0;
  }
   */
   
  .container_js {
    max-width: 70vw;
    margin: 100px auto;
    padding: 20px;  
    border: 1px solid #ccc;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
  }
  
  .header_js {
    background-color: #007bff;
    padding: 20px;
    color: #fff;
    text-align: center;
  }
  
  .header_js h1 {
    margin: 0;
  }
  
  .container_js p {
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .cta-button-wrapper_js {
    text-align: center;
  }
  
  .cta-button_js {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s;
  }
  
  .cta-button_js:hover {
    background-color: #0056b3;
  }
  
  .image_js {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .image_js img {
    max-width: 45%;
    height: auto;
  }

  .mailwe{
    color:#007bff;
  }
  
/* Base Styles */

.temp {
  height: 100px; /* Adjust the height as needed */
}

.whole_terms {
  padding: 20px;
}

.terms-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.terms-section {
  max-width: 95%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 5px 8px 10px 8px rgba(0, 0, 0, 0.3);
  background-color: #f9f9f9;
  transform: scale(1);
  transition: transform 0.5s ease-in-out;
  margin:auto
}

.terms-section:hover {
  transform: scale(1.05);
}

.contact {
  margin-top: 20px;
  text-align: center;
}

.contact a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.contact a:hover {
  color: #0056b3;
}

.txtx {
  color: #67738c;
}

.light {
  font-weight: 300;
}

strong {
  font-weight: bold;
}

/* Responsive Styles */

@media screen and (max-width: 768px) {
  .terms-section {
    max-width: 95%;
  }
}

.contact {
  margin-top: 20px;
  text-align: center;
}

.contact a {
  color: #007BFF;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.contact a:hover {
  color: #0056b3;
}

.terms p{
  font-size: 1.5em !important;
}

.hightlight{
  color:black;
}

.txtx{
  color:#67738c;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: 0;
  }
  body{
     /*Specify which one*/
       color: #fff;
  }
  .faqsection{ 
  min-height: 100vh;
  width: 100%;
  margin: 0 auto; 
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  
  
  .item{
  margin-bottom : 5px;
  padding : 10px 20px;
  }
  
  .title_faq{
  display : flex;
  justify-content: space-between;
  align-items : center; 
  cursor: pointer;
  }
  
  .content{
  max-height: 0;
  overflow: hidden;
  transition :  all 0.5s cubic-bezier(0,1,0,1);
  }
  
  .content.show {
  height : auto;
  max-height: 9999px;
  transition : all 0.5s cubic-bezier(1,0,1,0);
  }
  
  .sect{
    margin-top: 10vh;
    display: flex;
    width: 80%;
  
  }
  
  
  .faqsection{ 
    min-height: 100vh;
    width: 90%;
    margin: 0 auto; 
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .secondarycolor{
  color: #4DEBD4
  }
  .starter{
    display: flex;
    align-items: center;
    min-height: 20vh;  
    background : radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%);  
    width: 80%;
    border-radius: 10px;
    padding: 5%;
    margin-top: 5vh ;
  }
  
  
  .heading{
    font-size: 3rem;
    margin: 2rem 0rem; 
  }
  
  .faq{
    max-width: 700px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid #fff;
    cursor: pointer;
  }
  .faq_text{
    min-height: 50vh;
    min-width: 28vw;
  }
  
  .faq_text h1{
    margin: 5vh auto;
    /* font-weight: 700; */
    font-size: 2.5rem; 
    line-height: 33%;
  color: #4DEBD4;
  }
  .faq_text p{
    margin: 5vh auto;
  }
  
  .faq_list{
  margin-top: 5vh;
  }
  .question{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .question h3{
    font-size: 1.8rem;
  }
  
  .answer{
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.4s ease;
  }
  
  .answer p{
    padding-top: 1rem;
    line-height : 1.6;
    font-size: 1.4rem;
     
  }
  
  .faq.active .answer{
    max-height : 300px;
    animation: fade 0.5s ease-in-out;
  }
  
  .faq.active svg{
    transform: rotate(180deg);
  }
  
  /* svg{
    transition: transform 0.5s ease-in;
    pointer-events: none;
  } */
  
  
  @keyframes fade {
    from{
        opacity: 0;
        transform: translateY(-10px);
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
    
  }
  
  /* contact update css*/
  
  .contact p{
    font-size: 2rem;
    margin: 2rem 0rem; 
  }
  
  .contact h1{
    text-align: center;
    margin-bottom: 2vh;
  }
  
  
  /* grid for form */
  /*
  .item1 { grid-area: name; }
  .item2 { grid-area: nameInput; }
  .item3 { grid-area: mail; }
  .item4 { grid-area: mailInput; }
  .item5 { grid-area: question; }
  .item6 { grid-area: questionInput; }
  .item7 { grid-area: submit; }
  
  .grid_container {
  display: grid;
  grid-template-areas:
    'name name nameInput nameInput nameInput nameInput'
    'mail mail mailInput mailInput mailInput mailInput'
    'question question questionInput questionInput questionInput questionInput'
    'submit submit submit submit submit submit';
  gap: 10px;
  
  padding: 10px;
  } */
  
  .submitform{
    margin: 3vh auto;
    width: 40%;
    
  }
  
  
  .contact_flexo{
    display: flex;
    margin-top: 2vh;
    justify-content : space-around;
    align-items : center;
  }
  
  .grid-container > label {
  background-color: rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  }
  .grid-container > input
  {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50px;
    min-height: 30vh;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  }
  
  input[type=text],input[type=email]{
    background-color: rgba(255, 255, 255, 0.25);
    
  }
  
  .item6{
    min-height: 20vh;
  }
  .inputform{
    max-width: 70%;
  }
  
  
  
  /* form label{ */
    /* margin: 8px auto 8px 20px; */
    /* font-size: 1.5rem; */
  /* } */

  
  input[type=text], input[type=email],input[type=tel],select {
    background: rgba(76, 66, 66, 0.25);
    color: white;
    width: 90%;
    padding: 12px 20px;
    margin: 8px 8px 8px 0;
    display: inline-block;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 100%;
    background-color: #57D9CD;
    /* background-color: #007bff !important; */
    color: black;
    padding: 14px 20px;
    margin: 8px auto;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    transform: scale(1.1);
    transition: 0.3s;      /*Specify afterwards */
  }
  
  
  /*registration final*/
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: 0;
  }
  body{
       color: #fff;
  }
  .eventNamer{
    text-align: center;
    font-size: 9090rem;
  
  }
  .r_contact{
    min-width: 50vw;
  }
  .r_contact p{
    font-size: 2rem;
    margin: 2rem 0rem; 
  }
  
  .r_contact h1{
    text-align: center;
    margin-bottom: 2vh;
  }
  
  
  /* grid for form */
  /*
  .item1 { grid-area: name; }
  .item2 { grid-area: nameInput; }
  .item3 { grid-area: mail; }
  .item4 { grid-area: mailInput; }
  .item5 { grid-area: question; }
  .item6 { grid-area: questionInput; }
  .item7 { grid-area: submit; }
  
  .grid_container {
  display: grid;
  grid-template-areas:
    'name name nameInput nameInput nameInput nameInput'
    'mail mail mailInput mailInput mailInput mailInput'
    'question question questionInput questionInput questionInput questionInput'
    'submit submit submit submit submit submit';
  gap: 10px;
  
  padding: 10px;
  } */
  
  .r_submitform{
    margin: 3vh auto;
    width: 40%;
    
  }
  
  
  .r_contact_flexo{
    display: flex;
    margin-top: 2vh;
    justify-content : space-around;
    align-items : center;
  }
  
  .r_grid-container > label {
  background-color: rgba(255, 255, 255, 0.25);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
  }
  .r_grid-container > input
  {
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 50px;
    min-height: 30vh;
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
  }
  
  input[type=text],input[type=email],input[type=tel]{
    background-color: rgba(255, 255, 255, 0.25);
  }
  
  .r_item6{
    min-height: 10vh;
  }
  
  .r_item8{
    min-height: 8vh;
  }
  
  .r_subhead{
    margin-top: 10vh;
    /* color : #4DEBD4; */
    color : #007bff;
  }
  
  .r_inputform{
    max-width: 70%;
  }
  
  
  
  .r_grid_container label{
    margin: 8px auto 8px 20px;
    font-size: 1.5rem;
  }
  input[type=text],input[type=email],input[type=tel], select {
    /* background-color: rgba(255, 255, 255, 0.25) !important; */
    /* color: ; */
    font-size: 0.9rem;
    color:var(--text-color);
    width: 90%;
    padding: 12px 20px;
    margin: 8px 8px 8px 0;
    display: inline-block;
    border: none;
    border-radius: 15px;
    box-sizing: border-box;
  }
  
  input[type=submit] {
    width: 100%;
    background-color: #57D9CD;
    color: black;
    padding: 14px 20px;
    margin: 8px auto;
    border: none;
    border-radius: 15px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    transform: scale(1.1);
    transition: 0.3s;      
  }
  
  @media screen and (max-width : 700px)
  {
    
  .contact{
    margin-top: 2vh;
  }
  .contact p{
  text-align: center;
  font-size: 1.5rem;
  padding: auto 20px;
  }
  .contact h1{
    font-size: 2rem;
    margin: 6vh auto;
  }
  .contact_form{
    margin: 0vh 2vw;
  }
  input[type=text],input[type=email], select {
    font-size: 0.8rem;
  }
  .form_text{
    font-size : 1rem;
  }
  
  }
  /*Fegistration end*/
  
  
  @media screen and (max-width : 700px)
  {
    .starter h2{
      font-size: 1.9rem;
    }
  
    .item{
      margin : 0 auto;
      padding :0;
    }
    
  
  .sect{
    display: inline-block;  
    margin-top: 5vh;
  }
  .faq_text{
  min-height: auto;
  text-align: center;
  margin-bottom: 9vh;
  
  }
  .faq_text p{
    font-size: 0.8rem;
  }
  .faq_text h1{
    font-size: 2rem;
    padding: 5%;
  }
  .faq_text h2{
    display: none;
    font-size: 1.5rem;
  }
  
  .faq_list{
  margin-top: 2vh;
  }
  
  .faqsection{
  width: 100%;
  
  }
  .contact{
    margin-top: 2vh;
  }
  .contact p{
  text-align: center;
  font-size: 1.5rem;
  padding: auto 20px;
  }
  .contact h1{
    font-size: 2rem;
    margin: 6vh auto;
  }
  .contact_form{
    margin: 0vh 2vw;
  }
  input[type=text],input[type=email] ,input[type=tel],select {
    font-size: 0.8rem;
  }
  .form_text{
    font-size : 1rem;
  }
  }


  .toast-custom-style{
    font-family: 'Aldrich';
    font-size: 15px;
    color:white;
  }


  input[type="text"],input[type=email],input[type=tel]{
    /* background-color: rgba(59, 36, 36, 0.25); */
    color: black; 
  }

  .form_text input[type='email']{
    font-size: 1rem;
  }




.Appx {
height: 150vh;
  margin: 0;
  background: -webkit-linear-gradient(45deg, #49a09d, #5f2c82);
  background: linear-gradient(45deg, #49a09d, #5f2c82);
  font-family: sans-serif;
  font-weight: 100;
  color:black;
  font-size: 20px;
}
.container_table {
  position: absolute;
  top: 70%;
  left: 50%;
  max-height: 600px;
  overflow-y: scroll;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
table {
  width: 800px;
  /* height: 800px; */
  border-collapse: collapse;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
th,
td {
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  color:black;
}
th {
  text-align: left;
  border: 2px solid black;
}
thead th {
  background-color: #55608f;
}
tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.3);
}
tbody td {
  position: relative;
  border: 2px solid black;
}
tbody td:hover:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -9999px;
  bottom: -9999px;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: -1;
}
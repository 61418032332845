.our-services {
  padding: 1rem;
}

.page-title {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
}

.service-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 1rem; */
}

.service-section.reverse {
  flex-direction: row-reverse;
  /* margin-bottom: 1rem; */
}

.service-section-image {
  flex: 0 0 50%;
  max-width: 50%;
  overflow: hidden;
}

.service-section-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
  border-radius: 30px;
  outline: none;
}

.service-section-image:hover img {
  transform: scale(0.9);
}

.service-section-content {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 2rem;
}

.service-section-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-section-description {
  color: #555;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .service-section {
    flex-direction: column;
  }
  .service-section.reverse {
    flex-direction: column;
  }
  .service-section-image,
  .service-section-content {
    flex: 0 0 100%;
    max-width: 100%;
    outline: none;
  }

  .service-section-image img {
    transition: none;
  }
}

.services_title_type > p {
  width: 75%;
  margin: 0 4%;
}

.space {
  min-height:15vh;
  /* background-color:red; */
}

.service-section-content ul li {
  font-size: 0.8rem;
  margin-bottom: 5px;
}

.headline_image-anno {
  font-size: 1rem;
  font-weight: 600;
}

#second img {
  scale: 0.8;
}

#third img {
  scale: 0.9;
}
.weserves {
  padding: 20px;
  display: flex;
}
.weserves ul li {
  list-style: disclosure-closed;
}

.right_serves {
  width: 50%;
}

.left_serves {
  width: 50%;
}

@media (max-width: 700px) {
  .weserves {
    flex-direction: column;
  }
  .right_serves {
    width: 90%;
  }
  .left_serves {
    width: 90%;
  }
}

#video-data img {
  scale: 0.8;
}
#image-data img {
  scale: 0.8;
}
#Multilingual_Transcription img{
  scale:0.8;
}

#captions img{
  scale:0.7;
}

.weservetrans ul li{
  list-style:disclosure-closed;
  margin-bottom: 10px;
}

.weservetrans p{
  margin: 10px 0;
}

.weservetrans{
  margin: 30px;
}

.head_service h2{
  text-align: center;
  margin-bottom: 5px;
}

h2{
  position: relative;
}
/* 
.service-section-title::after{
  content: '';
  background: #303ef7;
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 2px;
}
 */
.why_us{
  text-align: center;
}

.why_us h2::after{
  content: '';
  background: #303ef7;
  width: 30vw;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
.why_us p{
  font-size: 21px;
}

.our-services{
  overflow-x: hidden;
} 
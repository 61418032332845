@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
nav{
  position: fixed;
  z-index: 99;
  width: 100vw;
  background: #fbfbfd;
  top:0;
}
nav .wrapper{
  position: relative;
  max-width: 100vw;
  padding: 0px 30px;
  height: 70px;
  line-height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.wrapper .logo a{
  color: #343c4c;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
.wrapper .nav-links{
  display: inline-flex;
}
.nav-links li{
  list-style: none;
}
.nav-links li a{
  color: #343c4c;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 9px 15px;
  border-radius: 5px;
  transition: all 0.3s ease;
 
}
.nav-links li a:hover{
  background: rgba(255,125,255,0.25);
  color:var(--text-color);
  
}
.nav-links .mobile-item{
  display: none;
}
.nav-links .drop-menu{
  position: absolute;
  background: #fbfbfd;;
  width: 180px;
  line-height: 45px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
  /* box-shadow: 0 6px 10px rgba(0,0,0,0.15); */
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
  border-radius: 11px;
}
.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box{
  transition: all 0.3s ease;
  top: 70px;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a{
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
  display: inline-block;
}
.mega-box{
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content{
max-height: fit-content;
  /* background: #242526; */
  background: #fbfbfd;
box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
  padding: 25px 20px;
  display: flex;
  width: 70%;
  justify-content: space-between;
  /* box-shadow: 0 6px 10px rgba(0,0,0,0.15); */
  margin-left: 20vw;
}
.mega-box .content .row{
  width: calc(50% - 30px);
  line-height: 40px;
}
.content .row img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header{
  color: #000;
  font-size: 20px;
  font-weight: 500; 
  margin: 0 5px;
  /* for more than 17 inches */
  display: flex;
  flex-direction: column;
}
.content .row .mega-links{
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li{
  padding: 0 20px;
}
.row .mega-links li a{
  padding: 0px;
  padding: 0 20px;
  color:#464444;;
  font-size: 17px;
  display: block;
}

.wrapper .btn{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.wrapper .btn.close-btn{
  position: absolute;
  right: 30px;
  top: 10px;
}

@media (max-width:1250px){
  .mega-box .content{
    width:99%;
    margin:0;
  }
}

@media screen and (max-width: 1025px) {
 

  .drop-menu li a{
    color:#000 !important;
  }
  .wrapper .btn{
    display: block;
  }
  .wrapper .nav-links{
    position: fixed;
    height: 100vh;
    width: 100%;
    max-width: 420px;
    top: 0;
    left: -100%;
    background: #242526;
    display: block;
    padding: 50px 10px;
    line-height: 50px;
    overflow-y: auto;
    box-shadow: 0px 15px 15px rgba(0,0,0,0.18);
    transition: all 0.3s ease;
  }
  /* custom scroll bar */
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #242526;
  }
  ::-webkit-scrollbar-thumb {
    background: #3A3B3C;
  }
  #menu-btn:checked ~ .nav-links{
    left: 0%;
  }
  #menu-btn:checked ~ .btn.menu-btn{
    display: none;
  }
  #close-btn:checked ~ .btn.menu-btn{
    display: block;
  }
  .nav-links li{
    margin: 15px 10px;
  }
  .nav-links li a{
    padding: 0 20px;
    display: block;
    font-size: 20px;
    color:white;
  }
  .nav-links .drop-menu{
    position: static;
    opacity: 1;
    top: 65px;
    visibility: visible;
    padding-left: 20px;
    width: 100%;
    max-height: 0px;
    overflow: hidden;
    box-shadow: none;
    transition: all 0.3s ease;
    
  }
  #showDrop:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
    max-height: 100%;
  }
/*  */
  #showDrop2:checked ~ .drop-menu,
  #showMega:checked ~ .mega-box{
    max-height: 100%;
  }


  #showMega2:checked ~ .mega-box{
    max-height: 100%;
  }
  .nav-links .desktop-item{
    display: none;
  }
  .nav-links .mobile-item{
    display: block;
    color: #f2f2f2;
    font-size: 20px;
    font-weight: 500;
    padding-left: 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
  .nav-links .mobile-item:hover{
    background: #3A3B3C;
    /* color:white; */
  }
  .drop-menu li{
    margin: 0;
  }
  .drop-menu li a{
    border-radius: 5px;
    font-size: 18px;
  }
  .mega-box{
    position: static;
    top: 65px;
    opacity: 1;
    visibility: visible;
    padding: 0 20px;
    max-height: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  .mega-box .content{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content .row:nth-child(1),
  .mega-box .content .row:nth-child(2){
    border-top: 0px;
  }
  .content .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  .row .mega-links li{
    margin: 0;
  }
  .content .row header{
    font-size: 19px;
  }
}
nav input{
  display: none;
}

.body-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  padding: 0 30px;
}
.body-text div{
  font-size: 45px;
  font-weight: 600;
}


.custom-button {
    background-color: var(--primary-color);
    color: var(--neutral-color-3);
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .custom-button:hover {
    background-color: var(--accent-color-2);
    color: var(--neutral-color-3);
  }
  
  .content{
    border-radius: 11px;
}
.row{
    padding-left: 10px;
}

.navi_color{
    color: black;
} 

.sticky-header {
  /* background-color: var(--background-color);
  background-color:var(--primary-color); */
  /* background-color: rgba(255,255,255,0.5);
   */
  /* background-image: linear-gradient(to right, transparent, white 20%, white 50%);
   */
   /* background-image: linear-gradient(to right, transparent); */
   backdrop-filter: saturate(180%)blur(5px);
background-color: rgba(251,251,253,.8);
}

.navi_close{
  color: aliceblue;
}


@media (max-width:360px){
  .wrapper .logo a {
    color: #343c4c;
    font-size: 23px;
    font-weight: 500;
    text-decoration: none;
  }
}



/* added  */

.mega-box .content2{
  max-height: fit-content;
    /* background: #242526; */
    background: #fbfbfd;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.3);
    padding: 25px 20px;
    display: flex;
    width: 45%;
    justify-content: space-between;
    /* box-shadow: 0 6px 10px rgba(0,0,0,0.15); */
    margin-left: 20vw;
  }
  .mega-box .content2 .row{
    width: calc(50% - 30px);
    line-height: 40px;
  }
  .content2 .row img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content2 .row header{
    color: #000;
    font-size: 20px;
    font-weight: 500;
    margin: 0 5px;
  }
  .content2 .row .mega-links{
    margin-left: -40px;
    border-left: 1px solid rgba(255,255,255,0.09);
  }




  
@media screen and (max-width: 1025px) {

  .row .mega-links li a {
    /* font-size: 11px; */

  }
  .mega-box .content2{
    width:100%;
    margin:0;
  }  
  .mega-box .content2{
    box-shadow: none;
    flex-direction: column;
    padding: 20px 20px 0 20px;
  }
  .mega-box .content2 .row{
    width: 100%;
    margin-bottom: 15px;
    border-top: 1px solid rgba(255,255,255,0.08);
  }
  .mega-box .content2 .row:nth-child(1),
  .mega-box .content2 .row:nth-child(2){
    border-top: 0px;
  }
  .content2 .row .mega-links{
    border-left: 0px;
    padding-left: 15px;
  }
  
  .content2 .row header{
    font-size: 19px;
  }
}

.content2{
  border-radius: 11px;
}


.pulse{
  animation: pulsex 1.8s infinite ;
}

@keyframes pulsex{
  0%{
box-shadow: 0 0 0 0 var(--primary-color);
  }
  65%{
box-shadow: 0 0 0 10px transparent;
  }
  100%{
    box-shadow: 0 0 0 0 transparent;
  }
}

.mobile_view_ind{
  display:none;
}

@media(max-width:967px){
.desktop_view_ind{
  display: none;
}
.mobile_view_ind{
  display: block;
}
}

.logoone{
  display: flex;
  align-items: center;
  width: 18vw;
  scale: 1.4;
}



@media (max-width:600px) {
  .logoone{
    scale:3.6;
  }
  .whole_wrapup input::placeholder {
    font-size: 8px;
  }
}

@media (max-width:1020px) and (min-width:600px){
  .logoone{
    scale:1.9;
  }
}
.upwards-arrow {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 60px;
    cursor: pointer;
    z-index: 99999;
  }
  .upwards-arrow:hover{
    cursor:grab;
  }
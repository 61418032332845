/* BlogPage.css */
.blog-container {
    max-width: 90vw;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-title {
    text-align: center;
    margin-top: 0;
  }
  
  .blog-intro {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .blog-image {
    width: 400px;
    height: auto;
    margin-right: 20px;
  }
  
  .blog-section-title {
    margin-top: 30px;
  }
  
  .blog-section-content {
    line-height: 1.5;
  }
  .blog_1_list ul li{
    list-style: disc;
  }

  .image_blog{
    height:50vh;
  }
  .image_blog img{
    height:35vh;
    width:auto
  }

  .blog-container p{
    color:#515151;
  }

  @media(max-width:850px){
.blog-intro{
  flex-direction: column;
}
.blog-image{
  /* width: 100vw; */
height: 50vh;
}
  }
.our-abouts {
    padding: 2rem;
  }
  
  .page-title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  #data-ano{
    scale:2;
  }
  
  .about-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4rem;
  }
  
  .about-section.reverse {
    flex-direction: row-reverse;
  }
  
  .about-section-image {
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;
  }
  
  .about-section-image img {
    /* width: 100%; */
    width: 90%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 30px;
    outline: none;
  }
  
  .about-section-image:hover img {
    transform: scale(0.9);
  }
  
  .about-section-content {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 2rem;

  }
  
  .about-section-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .about-section-description {
    color: #555;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .about-section {
      flex-direction: column;
    }
    .about-section.reverse {
        flex-direction: column;
      }
    .about-section-image,
    .about-section-content {
      flex: 0 0 100%;
      max-width: 100%;
      outline: none;
    }
  
    .about-section-image img {
      transition: none;
    }
  }
  

#let-s-transform{
    width:80%;
}

.security-page {
    max-width: 90vw;
    margin: 0 auto;
    padding: 20px;
    overflow-x:hidden;
  }
  
  .security-title {
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .security-content {
    font-size: 25px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .security-section {
    margin-top: 40px;
  }
  
  .security-section h2 {
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  
  .security-image {
    display: block;
    max-width: 50vh;
    /* margin:auto; */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: scale(1);
    transition: transform 0.3s ease-in-out;
  }
  
  .security-image:hover {
    transform: scale(0.8);
  }
  /* added  */

  
  .security-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0rem;
  }
  
  .security-section.reverse {
    flex-direction: row-reverse;
  }
  
  .security-section-image {
    flex: 0 0 50%;
    max-width: 50%;
    overflow: hidden;
  }
  
  .security-section-image img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    border-radius: 30px;
    outline: none;
  }
  
  .security-section-image:hover img {
    transform: scale(0.9);
  }
  
  .security-section-content {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 2rem;

  }
  
  .security-section-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .security-section-description {
    color: #555;
    line-height: 1.5;
  }
  
  @media (max-width: 768px) {
    .security-section {
      flex-direction: column;
    }
    .security-section.reverse {
        flex-direction: column;
      }
    .security-section-image,
    .security-section-content {
      flex: 0 0 100%;
      max-width: 100%;
      outline: none;
    }
  
    .security-section-image img {
      transition: none;
    }

    .security-section-description {
        color: #555;
        line-height: 1.5;
        font-size: 19px;
      }
  }



  



.containerx{
    max-width: 1170px;
    margin:auto;
    /* background-color: #24262b; */
    background-color: var(--second-background-color);
  }
  .rowx{
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
  ul{
    list-style: none;
  }
  .footer{
    /* background-color: #24262b; */
    background-color: var(--second-background-color);
      padding: 70px 0;
      width:100vw;
      margin-top: 70px;
  }
  .footer-col{
     width: 20%;
     padding: 0 15px;
     margin-top: 10px;
  }
  .footer-col h4{
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
  }
  .footer-col h4::before{
    content: '';
    position: absolute;
    left:0;
    bottom: -10px;
    /* background-color: #e91e63; */
    background-color: var(--secondary-color);
    height: 2px;
    box-sizing: border-box;
    width: 50px;
  }
  .footer-col ul li:not(:last-child){
    margin-bottom: 10px;
  }
  .footer-col ul li a{
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #bbbbbb;
    display: block;
    transition: all 0.3s ease;
  }
  .footer-col ul li a:hover{
    color: #ffffff;
    padding-left: 8px;
  }
  .footer-col .social-links a{
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,0.2);
    margin:0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .footer-col .social-links a:hover{
    color: #24262b;
    background-color: #ffffff;
  }
  
  /*responsive*/
  @media(max-width: 767px){
    .footer-col{
      width: 47%;
      margin-bottom: 30px;
    }
    
  .logo_fo{
    margin-left:25px;
  }
  .get_help{
    margin-left:25px;
  }
  }
  @media(max-width: 574px){
    .footer-col{
      width: 100%;
      margin-left:25px;
  }
  .logo_fo{
    margin-left:80px;
  }
  }
  
.curves{
  position: relative;
top: 5rem;
z-index: -1;
/* background: radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%); */
}

@media (max-width:500px){
.curves{
  position: relative;
  top:3rem;
}
}
@media (max-width:1020px){
.curves{
  position: relative;
  top: 3rem;
}
}


/* newsletter  */
.footerOuter{
  width: 60%;
  /* background-image: url("../../Assets/bggg.jpg") ; */
  /* background-image: linear-gradient(to right, #007BFF, #FFFFFF); */
  /* background-image: radial-gradient(circle, #FFFFFF, #007BFF); */
  background-image: linear-gradient(to right, #007BFF, #7BB2DF);
  /* box-shadow: 15px 2px 4px rgba(0, 0, 0, 0.1);  */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  object-fit: cover;
margin: auto;
border-radius:20px;
/* border:2px solid grey; */
/* z-index:90; */
position: relative;
top:6vh;
padding:20px
}

.footerUpperContainer{
  width: 70%;
margin: auto;

}

#footerJoinNow{
  /* width:10vw */
}

.btn_jn{
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerx{
  /* width:80%; */
  /* position: absolute; */
}

/* .emailRegistered{ */
  /* color:#00B100; */
/* } */
.emailNotRegistered{
  color: red;
}

.whole_wrapup{
  display: flex;
}

.btn_jj{
  padding: 10px;
height: 8vh;
/* width: 19vh; */
color:aliceblue;
align-items: center;
height: fit-content;
background: var(--primary-color);
border-radius: 20px;
position:absolute;
right:15vw;
}


@media (max-width:1250px){
  .footerOuter{
    /* top:7vh;
     */

     display: none;
  }
}


@media (max-width:640px){
  .footerOuter{
    display: block;
    top:7vh;
  }

  #footerInfoEntry{
    width: 80vw;
    height:7vh;
  }

  .btn_jj{
    right:14vw;
  }
}


#footerInfoEntry{
  border-radius: 50px;
}

/* 
@media(max-width:640px){
/* .footerOuter{
  display: none;
} */

@media (max-width: 640px) {
  .footerOuter {
    top: 8vh;
    width: 90%; /* Adjust the width as needed for smaller screens */
  }

  .footerUpperContainer {
    width: 80%;
  }

  #footerInfoEntry {
    width: 100%; /* Make the input field full-width on mobile */
    margin-right: 0; /* Reset the right margin to align left */
  }
}


.whole_outer_footer_text{
  text-align: center;
}


.terms_codn p{
  color:#85898f;
}

.terms_codn p:hover{
  color:aliceblue;
  /* transform: scaleX(1.2); */
  padding-left: 8px;
}

.terms_codn{
display: flex;
justify-content: center;
align-items: center;
}


.whole_wrapup input::placeholder{
  font-size:20px;
}


.tests p{
  margin-top: 56px;
  font-size: 16px 
}


.whole_ani {
    font-family: 'Montserrat', sans-serif;
    /* background: #1e293b; */
    color: #f8fafc;
  }
  
  .app_x{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  
  .tag-list {
    /* width: 30rem; */
    max-width: 100vw;
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    gap: 1rem 0;
    position: relative;
    padding: 1.5rem 0;
    overflow: hidden;
  }
  
  .loop-slider .inner {
    display: flex;
    width: fit-content;
    animation-name: loop;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: var(--direction);
    animation-duration: var(--duration);
  }
  
  .tag {
    display: flex;
    align-items: center;
    gap: 0 0.2rem;
    /* color: #e2e8f0; */
    font-size: 0.9rem;
    /* background-color: #334155; */
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    margin-right: 1rem;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.2rem 1.5rem rgba(0, 0, 0, 0.4);
  }
  
  .tag span {
    font-size: 1.2rem;
    color: #64748b;
  }
  
  .fade {
    pointer-events: none;
    /* background: linear-gradient(90deg, #1e293b, transparent 30%, transparent 70%, #1e293b); */
    position: absolute;
    inset: 0;
  }
  
  @keyframes loop {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
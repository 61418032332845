@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');

.title{
  text-align: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  color: #8D8DDA;
}

body{
  background-color: var(--background-color);

  font-family: 'Aldrich', 'sans-serif';
}

.mottoContainer{
  display: flex;
  align-items: center;
  justify-content: center;

  height: fit-content;

  height: 70vh;

  font-family: 'Aldrich';
}

.committeeContainer{
  text-align: center;
}

.mottoText {
  background: radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%) 
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60rem;
  height: 15rem;
  margin: 4rem;
  border-radius: 35px;
  z-index: 1;
  padding: 5vh;
  font-size: 1.5rem;
}


.commiteeTitle{
  text-align: center;
  margin-top: 12vh;
  margin-bottom: 7vh;
  color: #8D8DDA;
}

.boxContainer{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transform-style: preserve-3d;
}

.box{
  width: 15rem;
  height: 17rem;
  display: inline-block;
  margin-right: 4rem;
  margin-left: 4rem;
  margin-bottom: 2rem;
  color: white;
  background: rgba(33, 160, 169, 0.1);
  border-radius: 35px;
  padding: 1rem;
  transform-style: preserve-3d;
  text-align: center;
}

.boxTitle{
  position: absolute;
  top: 0.5rem;
  left: 0;
  width: 100%;
  transform-style: preserve-3d;
  transform: translate3d(0,0,75px);
  transition: 0.5s;
  opacity: 0;
  z-index: 10;
}

.boxContent{
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
  transform-style: preserve-3d;
  transform: translate3d(0,0,75px);
  transition: 0.5s;
  opacity: 0;
  z-index: 10;
}

.circle{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  transition: 0.5s;
  background-color: rgba(255, 255, 255, 0.35);
  transform-style: preserve-3d;
  transform: translate3d(-50%,-50%,50px);
  z-index: 10;
}

.scomImg{
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  max-width: 8rem;
  height: 8rem;
  z-index: 11;
  transition: 0.5s;
  transform-style: preserve-3d;
  transform: translate3d(-50%,-50%,50px);
}


@media only screen and (max-width:365px){
  .mottoText{
    height: 75%;
    font-size: 75% !important;
    margin: 75% !important;
  }

  .box{
    margin: 0.5rem !important;
    width: 14rem !important;
  }
}

@media only screen and (max-width: 700px){
  
  .mottoContainer{
    height: 40vh;
    margin: 2rem 0rem;
  }

  .mottoText{
    height: 15rem;

    font-size: 1rem;
    margin: 2rem !important;
  }

  .box{
    transform-style: none !important;
    transform: none !important;
  }

  .box:hover{
    transform-style: none !important;
    transform: none !important;
  }

  .boxTitle{
    top: 2.5rem;
    opacity: 1;
    transform-style: none !important;
    transform: none !important;
  }

  .boxContent{
    bottom: 2rem;
    opacity: 1;
    transform-style: none !important;
    transform: none !important;
  }

  .circle{
    transform-style: none !important;
    transform: none !important;
    top: 26%;
    left: 25%;
  }

  .scomImg{
    transform-style: none !important;
    transform: none !important;
    top: 26%;
    left: 25%;
  } 
}


@media (hover:none) {
  
  .mottoText{
    height: 15rem;
    font-size: 1rem;
    margin: 4rem;

    margin: 2rem;
    font-size: 1rem;
  }

  .boxContainer{
    transform-style: none !important;
    transform: none !important;

  }

  .box{
    transform-style: none !important;
    transform: none !important;
  }

  .box:hover{
    transform-style: none !important;
    transform: none !important;
  }

  .boxTitle{
    top: 2.5rem;
    opacity: 1;
    transform-style: none !important;
    transform: none !important;
  }

  .boxContent{
    bottom: 2rem;
    opacity: 1;
    transform-style: none !important;
    transform: none !important;
  }

  .circle{
    transform-style: none !important;
    transform: none !important;
    top: 26%;
    left: 25%;
  }

  .scomImg{
    transform-style: none !important;
    transform: none !important;
    top: 26%;
    left: 25%;

  }

}

@media (hover: hover) {

  .box:hover .boxTitle{
    top: 2rem;
    opacity: 1;
  }
  
  .box:hover .boxContent{
    bottom: 1.5rem;
    opacity: 1;
  }

  .box:hover .scomImg{
    transform: translate3d(-50%,-50%,100px);
  }
}

body{
    background-color: var(--background-color);
    max-width: 100%;
    overflow-x: hidden;
}

*{
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;
    margin:0;
    padding:0;
    color: var(--text-color);
}

.videoSection{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    padding: 0 100px;
    /* background: rgba(33, 160, 169, 0.5); */
    background:var(--background-color)
}
.video{
        width: 30rem;
        height: 17.25rem;
    background: rgba(255, 255, 255, 0.15); 
    border: 5.0245px solid #4DEBD4;
    border-radius: 20px;
}

/*tablet*/

/* mobile responsive */
@media only screen and (max-width:930px) 
{
    .videoContainer
    {
        width: 264px;
         height: 168px;
        border-radius: 8px;
        border: 2.0245px solid #4DEBD4;
    }
    .video{
        /* width: 600px;
        height: 400px; */
        width: 379px;
height: 250px;
    }
    .videoSection
    {
        height: 48vh;
        gap: 0rem;
        display: flex;
        flex-wrap: wrap;
       padding-top: 10px;
    }

    .videoSection h1
    {
        font-size: 24px;
      margin-bottom: 4px;
    }


    .timerline
    {
        display: none;
    }
}

@media only screen and (max-width:450px) 
{
    .videoContainer
    {
        width: 264px;
         height: 168px;
        border-radius: 8px;
        border: 2.0245px solid #4DEBD4;
    }
    .video{
        width: 264px;
        height: 168px;
    }
    .videoSection
    {
        height: 60vh;
        gap: 0rem;
        display: flex;
        flex-wrap: wrap;
       padding-top: 10px;
    }

    .videoSection h1
    {
        font-size: 24px;
      margin-bottom: 4px;
    }

    .videoText
    {
    text-align:center;
    }
    .videoSection{
        margin-top: 40px;
    }
   
}


.videoText h1{
    color:var(--text-color);
}

.whole_baout{
    /* width:100vw; */
    overflow-x: hidden;
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

.whole_ind {
  margin-top: 10vh;
  width: 100vw;
  overflow-x: hidden;
  /* background-color: #f0f0f0; */
  font-family: 'Open Sans', sans-serif;
  font-size: 14.5px;
  line-height: 1.4em;
  color: #404646;
}

.head_ind{
  display: flex;
  justify-items: center;
  justify-content: center;
  height:20vh;
}
/* .head_ind h1::after {
    content: "";
position: absolute;
left: 36.5%;
height: 4px;
width: 300px;
background-color: #3e3e88;
top: 26vh;
  } */
.industry_container {
  min-height: 100vh;
  width: 100%;
  margin: auto;
  padding: 1.6em;
  display: grid;
  justify-content: center;
  align-content: start;
  /* grid-gap: 4.6em;
   */
   grid-gap: 5em 14.6em;
  grid-template: auto / repeat(auto-fill, minmax(35em, 27em));
}

.industry_card {
  margin: 0;
  height: 15em;
  display: flex;
  background-color: white;
  /* box-shadow: 1px 3px 3px rgba(0, 10, 20, 0.06);
   */
   box-shadow: 4px 4px 4px 4px rgba(0, 10, 94, 0.10);
   /* border-radius: 20px; */
   /* width: 35vw; */
   scale: 1.1;
   border: 2px solid #f5efef;
   border-radius:9px;
}

.industry_card img {
  height: 100%;
  width: 50%;
  max-width: 50%;
  object-fit: cover;
  flex: 1 1 auto;
  border-radius: 9px;
  /* margin-left: 4px; */
  /* margin-top: 4px; */
  padding: 5px;
}

.industry_card:hover{
  scale: 1.4;
}

.industry_card-body {
  width: 12em;
  max-height: 100%;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  padding: 1.6em;
 
}

.industry_card-body button {
  min-width: 8.3em;
  flex: none;
  align-self: flex-start;
  margin-top: auto;
  padding: 0.6em 1.2em;
  font-size: 0.92em;
  color: #404646;
  background: none;
  border: 0.5px solid #777;
  border-radius: 2px;
}

.industry_card-body button:hover {
  border-color: #d099a0;
}

.industry_card-text {
  position: relative;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0.8em 0;
  padding: 0;
}

.industry_card:hover h3{
  color:rgb(49, 49, 221) !important;
}

.industry_card-text h3,
.industry_card-text p {
  margin-top: 0;
}

.industry_card-text:after {
  position: absolute;
  bottom: 0;
  content: "";
  width: 100%;
  height: 2.8em;
  background: linear-gradient(rgba(255, 255, 255, 0), white);
}

.industry_a {
  color: #d07777;
  text-decoration: none;
}

.industry_a:hover {
  text-decoration: underline;
}
@media (max-width: 970px){
    .head_ind h1::after{
        display: none;
    }
}

@media (max-width: 570px) {
    .head_ind h1::after{
        display: none;
    }
  .industry_container {
    grid-template: auto / 1fr;
    padding: 1.6em / 1.5;
  }

  .industry_card {
    flex: 1 1 auto;
    display: flex;
    background-color: white;
    /* box-shadow: 1px 3px 3px rgba(0, 10, 20, 0.06); */
    box-shadow: 5px 15px 12px rgba(0, 10, 20, 0.20);
    scale:1
  }
  .industry_card:hover{
    scale:1.1;
  }

  .industry_card img {
    max-width: 45%;
  }
}


/* .contt img{
  object-fit: contain;
}

.fiill img{
  object-fit: fill;
} */


.start_ind{
  width:70%;
  margin:auto;
}

.light{
  color:#67738c;
}


.start_ind h1{
  text-align: center;
}


.demo{
  border: none;
  color: #fff;
  background-image: linear-gradient(30deg, #0400ff, #4ce3f7);
  border-radius: 20px;
  background-size: 100% auto;
  font-family: inherit;
  font-size: 17px;
  padding: 0.6em 1.5em;
 }
 
 .demo:hover {
  background-position: right center;
  background-size: 200% auto;
  -webkit-animation: pulse 2s infinite;
  animation: pulse512 1.5s infinite;
 }
 
 @keyframes pulse512 {
  0% {
   box-shadow: 0 0 0 0 #05bada66;
  }
 
  70% {
   box-shadow: 0 0 0 10px rgb(218 103 68 / 0%);
  }
 
  100% {
   box-shadow: 0 0 0 0 rgb(218 103 68 / 0%);
  }
 }


 .btn_demo{
  margin: 3vh auto;
  display: flex;
  align-items: center;
  justify-content: center;
 }


 @media (max-width:640px){
  .demo {
    font-size: 12px;
  }
 }


/* Automotive, Healthcare, Agriculture, Finance, Retail, Sports, Education, Entertainment, Manufacturing, Energy  */
.start_ind p{
  text-align:center ;
}


.industry_card-text p {
  margin-top: 10px;
}
/* Add CSS styles for the page layout, transitions, and transforms */
.ecommerce-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }
  
  .alll{
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  
  .alll:hover {
    transform: scale(1.05);
  }
  
  .argri {
    text-align: center;
  }
  
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  #veh_sour{
    /* scale:0.6;
     */
     /* transform: scale(0.6);
      */
      scale: 0.8;
  }

  #veh_s{
    scale:0.9
  }
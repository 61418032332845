* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-family: "Open Sans", sans-serif;
    /* overflow-y:hidden; */
 
  }
  
  .temp{
    min-height: 15vh;
  }
  .wholeup{
    /* background: radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%); */
    display: grid;
    place-items: center;
    min-height: 100vh;
    /* background-color: #171717; */
    background-color: var(--background-color);
  }
  
  .pricing-plans {
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 64px;
  }
  
  .pricing-card {
    --col: #e4e4e7;
    position: relative;
    min-width: 360px;
    padding: 32px;
    padding-bottom: 96px;
    border-radius: 4px;
    border: 1px solid #262626;
    background-color: #26262620;
    box-shadow: 0 0 32px transparent;
    text-align: center;
  }
  
  .pricing-card.basic {
    --col: #0891b2;
  }
  
  .pricing-card.standard {
    --col: #059669;
  }
  
  .pricing-card.premium {
    --col: #c026d3
  }
  
  .pricing-card:hover {
    border-color: var(--col);
    /* background-color: #26262680; */
    background-color: #ada8a880;
    box-shadow: 0 0 32px #171717;
    transform: translateY(-16px) scale(1.02);
    transition: all 0.5s ease;
  }
  
  .pricing-card > *:not(:last-child) {
    margin-bottom: 18px;
  }
  
  .pricing-card .heading h4 {
    padding-bottom: 12px;
    color: var(--col);
    font-size: 24px;
    font-weight: normal;
  }
  
  .pricing-card .heading p {
    color: #a3a3a3;
    font-size: 14px;
    font-weight: lighter;
  }
  
  .pricing-card .price {
    position: relative;
    color: var(--col);
    font-size: 60px;
    font-weight: bold;
  }
  
  .pricing-card .price sub {
    position: absolute;
    bottom: 14px;
    color: #a3a3a3;
    font-size: 14px;
    font-weight: lighter;
  }
  
  .pricing-card .features li {
    padding-bottom: 16px;
    color: #5d5656;
    font-size: 16px;
    font-weight: lighter;
    text-align: left;
  }
  
  .pricing-card .features li i,
  .pricing-card .features li strong {
    color: rgb(84, 80, 80);
    font-size: 16px;
    text-align: left;
  }
  
  .pricing-card .features li strong {
    padding-left: 24px;
  }
  
  .pricing-card .cta-btn {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid var(--col);
    background-color: var(--col);
    color: #e4e4e7;
    font-size: 20px;
    font-weight: bold;
  }
  
  .pricing-card .cta-btn:active {
    background-color: transparent;
    color: var(--col);
    transition: all 0.3s ease;
  }
  

.hp{
display: flex;
justify-content: center;
align-items: center;
/* background: radial-gradient(100.18% 488.14% at 50% 50%, rgba(77, 235, 212, 0) 23.23%, rgba(77, 235, 212, 0.365) 85.73%); */
}


.offer-head{
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media  (max-width: 600px) {
.pricing-plans{
  padding: 30px;
}

/* *{
  /* overflow-x: hidden; */
/* }  */
}



.container_offer {
  width: 85%;
  margin: auto;
  padding-top: 50px;
  padding-bottom: 50px;
}
.wholedown{
  background: rgba(255,125,255,0.10);
}

.grid_offer {
  display: grid;
  grid-template-columns: 25% 25% 25%;
}

.card_offer {
  width: 325px;
  border-radius: 10px;
  padding: 30px 20px 59px 20px;
  border: 0;
}

.card1_offer {
  background: linear-gradient(to bottom right, #FC56F6, #A356FD);
  box-shadow: 3px 15px 30px rgba(171, 86, 252, 0.75);
}

.card2_offer {
  background: linear-gradient(to bottom right, #56D0FD, #56FD7D);
  box-shadow: 3px 15px 30px rgba(86, 247, 142, 0.75);
}

.card3_offer {
  background: linear-gradient(to bottom right, #FDBD56, #FD56B6);
  box-shadow: 3px 15px 30px rgba(253, 99, 169, 0.75);
}

.card_offer h3 {
  color: rgba(255, 255, 255, 0.3);
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0px;
}

.card_offer h4 {
  color: white;
  font-size: 40px;
  margin-top: 53px;
}

.card_offer h2 {
  color: rgba(255, 255, 255, 0.15);
  font-size: 118px;
  font-weight: 900;
  position: absolute;
  margin-left: -6px;
  pointer-events: none;
}

.card_offer hr {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.15);
  margin-top: 53px;
}

.card_offer p:first-of-type {
  margin-top: 40px;
}

.card_offer p {
  color: white;
  font-size: 24px;
}

.card_offer p:nth-of-type(2) {
  margin-top: 10px;
  margin-bottom: 40px;
}

.card_offer a {
  color: white;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  /* padding: 12px 85px 11px 85px; */
  padding:10px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-left: 40px;
  border-radius: 6px;
  transition: background-color 0.5s;
}

.card_offer a:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

/* @media only screen and (max-width: 1900px) {
  .grid_offer {
    /* grid-template-columns: 100%; */
    /* grid-row-gap: 50px; */
  /* } */
/* } */ 

.grid_offer{
  grid-gap:170px;
}
/* 
@media (max-width:500px) {
  .grid_offer{
    grid-template-columns: 100%; 
    grid-row-gap: 50px;
  }
  .wholeup{
    overflow-x: hidden;
    overflow-y: auto; 
  } */

/* }
@media (min-width:600 ) and (max-width:1020px) {
  .grid_offer{
    grid-template-columns: 50% 50%; 
    grid-row-gap: 40px;
    grid-column-gap: 70px;
  }
  .wholeup{
    overflow-x: hidden; /* Added property */
    /* overflow-y: auto;  */
    /* max-width:100vh; */
    /* } */
/* } */

@media only screen and (max-width: 600px) {
  .wholeup {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .grid_offer {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .card_offer {
    flex: 0 0 auto;
    margin-right: 10px;
    scroll-snap-align: start;
  }
}

@media (max-width:500px){
  .background_font{
    display: none;
  }
}



.choose_section{
  display: flex;
  /* height: 20vh; */
}
.left_choose{
  width:40vw;
  /* height: 20vh; */
}
.right_choose{
  width:55vw;
  /* height: 20vh; */
}

@media (max-width:1020px){
  .choose_section{
    display: flex;
  flex-direction: column;
  }
  .left_choose{
    width: 100vw;
  }
  .right_choose{
    width: 100vw;
    padding: 10px;
  }
}


@media(max-width:500px){
  .pricing-plans{
    width: 100vw;
  }
  .pricing-card{
    width:310px;
  }
  .pricing-card .features li{
    font-size: 12px;
  }
}


@media(max-width:360px){
  .pricing-plans{
    width: 100vw;
  }
  .pricing-card{
    min-width:280px;
  }
  .pricing-card .features li{
    font-size: 10px;
  }
}


@media (min-width:550px) and (max-width:800px){
  .card_offer{
    width:222px;
  }
  .grid_offer{
    gap:13px;
    cursor: grabbing;
  }
}

@media (min-width:800px) and (max-width:1000px){
  .grid_offer{
    gap:20px;
  }
}
.right_choose{
  margin-top:70px;
}

@media (max-width:900px){
  .right_choose{
    margin-top:20px;
    /* margin-left:10px; */
    margin-right: 10px;
  }
}

.right_choose p{
  font-size: 22px;
}

.right_choose{
  margin: 10px 10px;
}

.left_choose{
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width:700px){
  .wholewhole{
    overflow: hidden;
  }
}


/* .yesthisone h1::after{
  background: #303ef7;
  width: 154px;
  height: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: "";
  top:10%
} */


.yesthisone h1{
  border-bottom: 5px solid blue; /* Adjust the thickness and color as needed */
  padding-bottom: 0px; 
}


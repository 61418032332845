
h1 {
    font-weight: normal;
  }
  a {
    text-decoration: none;
    font-weight: bold;
    /* background: linear-gradient(135deg, rgba(43,171,217,1) 0%, rgba(0,130,200,1) 100%);
    -webkit-background-clip: text; */
    color: transparent;
  }
  .cardxxxx {
    position: absolute;
    background-color: white;
    border-radius: 1.5em;
    min-width: 600px;
    max-width: 1170px;
    width: 25%;
    padding: 2.6em 3.8em;
    cursor: pointer;
    top: 30%;
    transform: translate(-50%, -50%);
    left: 26%;
    /* box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1), 
      0 8px 8px rgba(0, 0, 0, 0.1),
      0 16px 16px rgba(0, 0, 0, 0.1), 
      0 32px 32px rgba(0, 0, 0, 0.15),
      0 64px 64px rgba(0, 0, 0, 0.15); */
  }
  .note::after {
    content: '';
    width: 20%;
    height: 2px;
    border-radius: 999px;
    background-color: #AFAFAF;
    background: linear-gradient(135deg, rgba(43,171,217,1) 0%, rgba(0,130,200,1) 100%);
    position: absolute;
    top: -1.8em;
    left: 0;
  }
  .note {
    font-size: 0.8em;
    color: #8A8A8A;
    position: relative;
    margin-top: 4em;
  }
  
  /* Scroller styling */
  .scroller {
    height: 1.2em;
    line-height: 1.2em;
    position: relative;
    overflow: hidden;
    width: 10em;
  }
  .scroller > span {
    position: absolute;
    top: 0;
    animation: slide 5s infinite;
    font-weight: bold;
    
  }
  @keyframes slide {
    0% {
      top: 0;
    }
    25% {
      top: -1.2em;
    }
    50% {
      top: -2.4em;
    }
    75% {
      top: -3.6em;
    }
    100%{
        top:-4.8em
    }
  }


  .whole_sliding_effect{
    min-height: 40vh;
  }

  @media (max-width:960px){
    .cardxxxx{
      left:50%;
    }
  }
  @media  (max-width:500px) {
    .cardxxxx{
 left:81%
    }
  }


  @media (max-width:600px){
    .whole_sliding_effect{
      display: none;
    }
  }